<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('damReport.division_wise_average_market_price_report') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(loadData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="4" md="4" sm="12">
                        <ValidationProvider name="Division" vid="division_id" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="division_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('org_pro_division.division') }} <span class="text-danger">*</span>
                            </template>
                            <vue-multi-select
                                id="division_id"
                                v-model="values_division"
                                search
                                historyButton
                                :filters="filters_division"
                                :options="options"
                                :selectOptions="divisionList"
                                :state="errors[0] ? false : (valid ? true : null)"
                                class="w-100"
                                :btnLabel="btnLabel"
                                btnClass="w-100 d-block text-left"
                                />
                            <div class="invalid-feedback d-block" v-if="errors.length && clickSubmit">
                                {{ errors[0] }}
                            </div>
                            <!-- <b-form-select
                              plain
                              v-model="formData.division_id"
                              :options="divisionList"
                              id="division_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.all')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div> -->
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" md="4" sm="12">
                        <ValidationProvider name="Commodity Group" vid="com_grp_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="com_grp_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('crop_price_config.commodity_group') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="formData.com_grp_id"
                              :options="commodityGroupList"
                              id="com_grp_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" md="4" sm="12">
                        <ValidationProvider name="Commodity Sub Group" vid="com_subgrp_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="com_grp_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('crop_price_config.commodity_sub_group') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="formData.com_subgrp_id"
                              :options="commoditySubGroupList"
                              id="com_subgrp_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" md="4" sm="12">
                        <ValidationProvider name="Commodity Name" vid="commodity_id" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="commodity_id"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('crop_price_config.commodity_name') }} <span class="text-danger">*</span>
                            </template>
                            <vue-multi-select
                                id="commodity_id"
                                v-model="values_commodity"
                                search
                                historyButton
                                :filters="filters_commodity"
                                :options="options"
                                :selectOptions="commodityNameList"
                                :state="errors[0] ? false : (valid ? true : null)"
                                class="w-100"
                                :btnLabel="btnLabel"
                                btnClass="w-100 d-block text-left"
                                />
                            <div class="invalid-feedback d-block" v-if="errors.length && clickSubmit">
                                {{ errors[0] }}
                            </div>
                            <!-- <b-form-select
                              plain
                              v-model="formData.commodity_id"
                              :options="commodityNameList"
                              id="commodity_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div> -->
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" md="4" sm="12">
                        <ValidationProvider name="Start Date" vid="start_date" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="start_date"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('birtan.start_date')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              class="datepicker"
                              v-model="formData.start_date"
                              :state="errors[0] ? false : (valid ? true : null)"
                              :placeholder="$t('crop_price_info.date_figure')"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" md="4" sm="12">
                        <ValidationProvider name="End Date" vid="end_date" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="end_date"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('birtan.end_date')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              class="datepicker"
                              v-model="formData.end_date"
                              :state="errors[0] ? false : (valid ? true : null)"
                              :placeholder="$t('crop_price_info.date_figure')"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col md="12">
                        <b-button type="submit" variant="primary" class="float-right">{{ $t('globalTrans.search') }}</b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row >
          <b-col md="12" v-if="showData">
            <b-overlay :show="loading">
              <iq-card v-if='datas.length'>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('damReport.division_wise_average_market_price_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new mr-2" @click="pdfExport">
                            <i class="far fa-file-pdf"></i>{{ $t('globalTrans.export_pdf') }}
                          </b-button>
                          <export-excel
                            class="btn btn_add_new"
                            :data="excelData"
                            :title="headerValue"
                            :before-generate = "headerDataExcel"
                            worksheet="Report Sheet"
                            name="division-wise-average-market-price-report.xls">
                            <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                          </export-excel>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :base-url="warehouseServiceBaseUrl" uri="/master-warehouse-report-heading/detail" :org-id="13">
                                        <template v-slot:projectNameSlot>
                                          {{ }}
                                        </template>
                                        {{ $t('damReport.division_wise_average_market_price_report') }}
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <div class="text-center">
                                        <table style="width:100%;color:black;">
                                          <tr>
                                            <td align="right" style="width:15%">{{ $t('crop_price_config.commodity_group') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:10%">{{ ($i18n.locale==='bn') ? formData.commodity_group_name_bn : formData.commodity_group_name_en }}</td>
                                            <td align="right" style="width:15%">{{ $t('crop_price_config.commodity_sub_group') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:10%">{{ ($i18n.locale==='bn') ? formData.commodity_sub_group_name_bn : formData.commodity_sub_group_name_en }}</td>
                                           </tr>
                                          <tr>
                                            <td align="right" style="width:15%">{{ $t('crop_price_config.commodity_name') }}</td>
                                            <td align="center" style="width:5%">:</td>
                                            <td align="left" style="width:10%">{{ getCommodityName(formData.commodity_id) }}</td>
                                          </tr>
                                          <tr>
                                            <slot v-if="formData.division_id">
                                              <td align="right" style="width:5%">{{ $t('org_pro_division.division') }}</td>
                                              <td align="center" style="width:2%">:</td>
                                              <td align="left" style="width:45%">{{ getDivisionName(formData.division_id) }}</td>
                                            </slot>
                                            </tr>
                                            <tr>
                                                <td align="right" style="width:15%">{{ $t('birtan.start_date') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:10%">{{ formData.start_date | dateFormat }}</td>
                                                <td align="right" style="width:15%">{{ $t('birtan.end_date') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:10%">{{ formData.end_date | dateFormat }}</td>
                                            </tr>
                                        </table>
                                      </div>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col sm="12" class="steaky-header">
                                      <slot>
                                        <b-table head-variant="primary" :sticky-header="stickyHeader" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="datas" :fields="columns">
                                          <template v-slot:cell(avg_retail_lowestPrice)="data">
                                            {{ $n(parseFloat(data.item.avg_retail_lowestPrice).toFixed(2)) + '-' + $n(parseFloat(data.item.avg_retail_howestPrice).toFixed(2)) }}
                                          </template>
                                          <template v-slot:cell(avg_wholesale_lowestPrice)="data">
                                            {{ $n(parseFloat(data.item.avg_wholesale_lowestPrice).toFixed(2)) + '-' + $n(parseFloat(data.item.avg_wholesale_highestPrice).toFixed(2)) }}
                                          </template>
                                        </b-table>
                                      </slot>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
              <div class="panel-body text-center mt-3" v-else>
                <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
              </div>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriMarketingServiceBaseUrl, warehouseServiceBaseUrl } from '@/config/api_config'
import { divisionWiseAvgPriceReport } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'
import { dateFormat } from '@/Utils/fliter'
import Store from '@/store'
import excel from 'vue-excel-export'
import Vue from 'vue'
import vueMultiSelect from 'vue-multi-select'
import 'vue-multi-select/dist/lib/vue-multi-select.css'
Vue.use(excel)

export default {
    props: ['id'],
    components: {
      ValidationObserver,
      ValidationProvider,
      ListReportHead,
      vueMultiSelect
    },
    created () {
      if (this.id) {
        const tmp = this.getEditingData()
        this.formData = tmp
      }
    },
    data () {
      return {
        btnLabel: (values) => values.length ? this.getNames(values) : this.$t('globalTrans.select'),
        btnLabelCommodity: (values) => values.length ? this.getNames(values) : this.$t('globalTrans.select'),
        options: {
              multi: true,
              groups: false,
              labelName: 'text',
              labelValue: 'value',
              cssSelected: option => (option.selected ? { 'background-color': '#5764c6' } : '')
            },
        filters_division: [{
              nameAll: this.$t('globalTrans.select_all'),
              nameNotAll: this.$t('globalTrans.deselect_all'),
              func () {
                return true
              }
            }],
        filters_commodity: [{
            nameAll: this.$t('globalTrans.select_all'),
            nameNotAll: this.$t('globalTrans.deselect_all'),
            func () {
            return true
            }
        }],
        values_division: [],
        values_commodity: [],
        formData: {
          division_id: [],
          com_grp_id: '0',
          com_subgrp_id: '0',
          commodity_id: [],
          start_date: '',
          end_date: '',
          commodity_group_name_en: '',
          commodity_group_name_bn: '',
          commodity_sub_group_name_en: '',
          commodity_sub_group_name_bn: '',
          commodity_name_bn: '',
          commodity_name_en: '',
          division_name_en: '',
          division_name_bn: ''
        },
            stickyHeader: true,
            listItemofyerterday: '',
            commoditySubGroupList: [],
            commodityNameList: [],
            warehouseServiceBaseUrl: warehouseServiceBaseUrl,
            headerExcelDefault: {
            orgName: null,
            orgNameBn: null,
            address: null,
            address_bn: null
        },
        datas: [],
        showData: false,
        clickSubmit: false
      }
    },
    mounted () {
      core.index()
      this.headerDataExcel()
      flatpickr('.datepicker', {})
    },
    computed: {
      headerValue: function () {
        const headerVal = []
        if (this.$i18n.locale === 'en') {
                headerVal[0] = this.headerExcelDefault.orgName
                headerVal[1] = this.headerExcelDefault.address
                headerVal[2] = this.$t('damReport.division_wise_average_market_price_report')
                headerVal[3] = this.$t('crop_price_config.commodity_group') + ':  ' + this.formData.commodity_group_name_en + '; ' + ' ' + this.$t('crop_price_config.commodity_sub_group') + ': ' + this.formData.commodity_sub_group_name_en + '; ' + ' ' + this.$t('crop_price_config.commodity_name') + ' : ' + this.formData.commodity_name_en
                headerVal[4] = this.$t('globalTrans.from_date') + ':  ' + this.formData.start_date + '; ' + ' ' + this.$t('globalTrans.to_date') + ': ' + this.formData.end_date
              } else {
                headerVal[0] = this.headerExcelDefault.orgNameBn
                headerVal[1] = this.headerExcelDefault.address_bn
                headerVal[2] = this.$t('damReport.division_wise_average_market_price_report')
                headerVal[3] = this.$t('crop_price_config.commodity_group') + ':  ' + this.formData.commodity_group_name_bn + '; ' + ' ' + this.$t('crop_price_config.commodity_sub_group') + ': ' + this.formData.commodity_sub_group_name_bn + '; ' + ' ' + this.$t('crop_price_config.commodity_name') + ' : ' + this.formData.commodity_name_bn
                headerVal[4] = this.$t('globalTrans.from_date') + ':  ' + dateFormat(this.formData.start_date) + '; ' + ' ' + this.$t('globalTrans.to_date') + ': ' + dateFormat(this.formData.end_date)
              }
        return headerVal
      },
      excelData: function () {
        const listData = this.datas
        var serial = 0
        const listContractor = listData.map(keyItem => {
          serial++
          if (this.$i18n.locale === 'en') {
              return {
                'sl ': serial,
                'Division Name': keyItem.division_name,
                'Commodity Name': keyItem.commodity_name,
                'Retail Unit': keyItem.unit_name,
                'Retail ': this.$n(parseFloat(keyItem.avg_retail_lowestPrice).toFixed(2)) + '--' + this.$n(parseFloat(keyItem.avg_retail_howestPrice).toFixed(2)),
                'Whole Sale Unit': keyItem.w_unit_name,
                'Wholesale ': parseFloat(keyItem.avg_wholesale_lowestPrice).toFixed(2) + '--' + parseFloat(keyItem.avg_wholesale_highestPrice).toFixed(2)
              }
              } else {
                return {
                  'ক্রমিক সংখ্যা': this.$n(serial),
                  'বিভাগ ': keyItem.division_name_bn,
                  'পণ্যের নাম': keyItem.commodity_name_bn,
                  'খুচরা বিক্রয়ের একক ': keyItem.unit_name_bn,
                  'গড় খুচরা মূল্য': this.$n(parseFloat(keyItem.avg_retail_lowestPrice).toFixed(2)) + '--' + this.$n(parseFloat(keyItem.avg_retail_howestPrice).toFixed(2)),
                  'পাইকারী বিক্রয়ের একক': keyItem.w_unit_name_bn,
                  'গড় পাইকারী দর': this.$n(parseFloat(keyItem.avg_wholesale_lowestPrice).toFixed(2)) + '--' + this.$n(parseFloat(keyItem.avg_wholesale_highestPrice).toFixed(2))
                }
              }
        })
        return listContractor
      },
      divisionList: function () {
        return this.$store.state.agriMarketing.damData.divisionList
      },
      commodityGroupList: function () {
        return this.$store.state.agriMarketing.commonObj.commodityGroupList
      },
      i18 () {
        return this.$i18n.locale
      },
        columns () {
          const labels = [
              { label: this.$t('globalTrans.division'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.product_name_and_description'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.measurement'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.average_retail_price'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.measurement'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.average_wholesale_price'), class: 'text-center', sortable: true }
            ]
          let keys = []

          if (this.$i18n.locale === 'bn') {
            keys = [
              { key: 'division_name_bn' },
              { key: 'commodity_name_bn' },
              { key: 'unit_name_bn' },
              { key: 'avg_retail_lowestPrice' },
              { key: 'w_unit_name_bn' },
              { key: 'avg_wholesale_lowestPrice' }
            ]
          } else {
            keys = [
              { key: 'division_name' },
              { key: 'commodity_name' },
              { key: 'unit_name' },
              { key: 'avg_retail_lowestPrice' },
              { key: 'w_unit_name' },
              { key: 'avg_wholesale_lowestPrice' }
            ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
        },
        loading () {
          return this.$store.state.commonObj.loading
        }
    },
    watch: {
      'formData.com_grp_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.commoditySubGroupList = this.getCommoditySubGroupList(newVal)
        }
      },
      'formData.com_subgrp_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.commodityNameList = this.getCommodityNameList(newVal)
        }
      }
    },
    methods: {
        getDivisionName (ids) {
          if (Array.isArray(ids)) {
            var arr = []
            ids.forEach(id => {
              const obj = this.$store.state.agriMarketing.damData.divisionList.find(item => item.value === parseInt(id))
              arr.push(obj?.text)
            })
            return arr.join(', ')
          } else {
            const data = this.$store.state.agriMarketing.damData.divisionList.find(item => item.value === ids)
            return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
          }
        },
        getCommodityName (ids) {
          if (Array.isArray(ids)) {
            var arr = []
            ids.forEach(id => {
              const obj = this.$store.state.agriMarketing.commonObj.commodityNameList.find(item => item.value === parseInt(id))
              arr.push(obj?.text)
            })
            return arr.join(', ')
          } else {
            const data = this.$store.state.agriMarketing.commonObj.commodityNameList.find(item => item.value === ids)
            return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
          }
        },
        getNames (list) {
            const arr = []
            list.forEach(item => {
            arr.push(item?.text)
            })
            const str = arr.join(', ')
            return str.length > 50 ? str.substring(0, 40) + '..' : str
        },
       headerDataExcel () {
        RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail/13').then(response => {
          if (response.success) {
            const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 13)
            const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
            const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
            this.headerExcelDefault.orgName = orgName
            this.headerExcelDefault.orgNameBn = orgNameBn
            this.headerExcelDefault.address = response.data.address
            this.headerExcelDefault.address_bn = response.data.address_bn
          }
        })
      },
      getCommoditySubGroupList (commodityGroupId) {
        const commoditySubGroupList = this.$store.state.agriMarketing.commonObj.commoditySubGroupList.filter(item => item.status === 1 && item.commodity_group_id === commodityGroupId)
        return commoditySubGroupList.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text }
          }
        })
      },
      getCommodityNameList (commoditySubGroupId) {
        const commodityNameList = this.$store.state.agriMarketing.commonObj.commodityNameList.filter(item => item.status === 1 && item.commodity_sub_group_id === commoditySubGroupId)
        return commodityNameList.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text }
          }
        })
      },
      pdfExport () {
        const reportTitle = this.$t('damReport.division_wise_average_market_price_report')
        ExportPdf.exportPdfDetails(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', 13, reportTitle, this.datas, this, this.formData)
      },
      getEditingData () {
        const tmpData = this.$store.state.list.find(item => item.id === this.id)
        return JSON.parse(JSON.stringify(tmpData))
      },

      async loadData () {
        RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail/13').then(response => {
            if (response.success) {
              const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 13)
              const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
              const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
              this.headerExcelDefault.orgName = orgName
              this.headerExcelDefault.orgNameBn = orgNameBn
              this.headerExcelDefault.address = response.data.address
              this.headerExcelDefault.address_bn = response.data.address_bn
            }
          })
        Store.commit('mutateCommonProperties', {
          loading: true
        })
        this.showData = true
        const commoditySubGroupList = this.$store.state.agriMarketing.commonObj.commoditySubGroupList
        // const commodityNameList = this.$store.state.agriMarketing.commonObj.commodityNameList
        // const divisionList = this.$store.state.agriMarketing.damData.divisionList
        const commodityGroup = this.commodityGroupList.find(item => item.value === this.formData.com_grp_id)
        this.formData.commodity_group_name_en = typeof commodityGroup !== 'undefined' ? commodityGroup.text_en : ''
        this.formData.commodity_group_name_bn = typeof commodityGroup !== 'undefined' ? commodityGroup.text_bn : ''
        const commoditySubGroup = commoditySubGroupList.find(item => item.value === this.formData.com_subgrp_id)
        this.formData.commodity_sub_group_name_en = typeof commoditySubGroup !== 'undefined' ? commoditySubGroup.text_en : ''
        this.formData.commodity_sub_group_name_bn = typeof commoditySubGroup !== 'undefined' ? commoditySubGroup.text_bn : ''
        // const commodityName = commodityNameList.find(item => item.commodity_sub_group_id === this.formData.com_subgrp_id)
        // this.formData.commodity_name_en = typeof commodityName !== 'undefined' ? commodityName.text_en : ''
        // this.formData.commodity_name_bn = typeof commodityName !== 'undefined' ? commodityName.text_bn : ''
        // const division = divisionList.find(item => item.value === this.formData.division_id)
        // this.formData.division_name_en = typeof division !== 'undefined' ? division.text_en : ''
        // this.formData.division_name_bn = typeof division !== 'undefined' ? division.text_bn : ''
        const divisionIds = []
        this.values_division.forEach(item => {
          divisionIds.push(parseInt(item.value))
        })
        this.formData.division_id = divisionIds
        const commodityIds = []
        this.values_commodity.forEach(item => {
          commodityIds.push(parseInt(item.value))
        })
        this.formData.commodity_id = commodityIds
        await RestApi.getData(agriMarketingServiceBaseUrl, divisionWiseAvgPriceReport, this.formData).then(response => {
          if (response.success) {
            Store.commit('mutateCommonProperties', {
              loading: false
            })
            if (response.data) {
              this.datas = this.getRelationalData(response.data)
            }
          } else {
            this.datas = []
            Store.commit('mutateCommonProperties', {
              loading: false
            })
          }
        })
      },
        getRelationalData (data) {
          const commodityNameList = this.$store.state.agriMarketing.commonObj.commodityNameList
          const list = data.map(item => {
            const divisionObj = this.$store.state.agriMarketing.damData.divisionList.find(com => com.value === item.division_id)
            const commodityObj = commodityNameList.find(com => com.value === item.commodity_id)
            const rUnitObj = this.$store.state.agriMarketing.commonObj.measurementUnitList.find(unit => unit.value === item.unit_retail)
            const wUnitObj = this.$store.state.agriMarketing.commonObj.measurementUnitList.find(unit => unit.value === item.unit_whole_sale)
            const divisionData = {
              division_name: divisionObj !== undefined ? divisionObj.text_en : '',
              division_name_bn: divisionObj !== undefined ? divisionObj.text_bn : ''
            }
            const commodityData = {
              commodity_name: commodityObj !== undefined ? commodityObj.text_en : '',
              commodity_name_bn: commodityObj !== undefined ? commodityObj.text_bn : ''
            }
            const rUnitData = {
              unit_name: typeof rUnitObj !== 'undefined' ? rUnitObj.text_en : '',
              unit_name_bn: typeof rUnitObj !== 'undefined' ? rUnitObj.text_bn : ''
            }
            const wUnitData = {
              w_unit_name: typeof wUnitObj !== 'undefined' ? wUnitObj.text_en : '',
              w_unit_name_bn: typeof wUnitObj !== 'undefined' ? wUnitObj.text_bn : ''
            }
            return Object.assign({}, item, commodityData, wUnitData, rUnitData, divisionData)
          })
          return list
        }
    }
}
</script>
