import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, newDatas, vm, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
        pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
        {
          columns: reportHeadData.reportHeadColumn, style: 'main_head'
        },
        { text: reportHeadData.address, style: 'address', alignment: 'center' }
      ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        const allRowsHead = [
          [
            { text: vm.$t('crop_price_config.commodity_group'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.commodity_group_name_bn : search.commodity_group_name_en, alignment: 'left', style: 'search' },
            { text: vm.$t('crop_price_config.commodity_sub_group'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.commodity_sub_group_name_bn : search.commodity_sub_group_name_en, alignment: 'left', style: 'search' },
            { text: vm.$t('crop_price_config.commodity_name'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: vm.getCommodityName(search.commodity_id), alignment: 'left', style: 'search' }
          ]
        ]
        if (search.division_id) {
          allRowsHead.push([
            { text: vm.$t('org_pro_division.division'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: vm.getDivisionName(search.division_id), alignment: 'left', style: 'search' },
            { text: vm.$t('birtan.start_date'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: dateFormat(search.start_date), alignment: 'left', style: 'search' },
            { text: vm.$t('birtan.end_date'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: dateFormat(search.end_date), alignment: 'left', style: 'search' }
          ])
        } else {
          allRowsHead.push([
            { text: vm.$t('birtan.start_date'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: dateFormat(search.start_date), alignment: 'left', style: 'search' },
            { text: vm.$t('birtan.end_date'), alignment: 'right', style: 'search' },
            { text: ':', style: 'search', alignment: 'center' },
            { text: dateFormat(search.end_date), alignment: 'left', style: 'search' },
            {},
            {},
            {}
          ])
        }
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '1%', '22%', '16%', '1%', '16%', '10%', '1%', '22%'],
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRows = [
          [
            { text: vm.$t('org_pro_division.division'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('crop_price_config.commodity_name'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('crop_price_config.unit_retail'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('damReport.retail_price'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('crop_price_config.unit_whole_sale'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('damReport.wholesale_price'), style: 'th', alignment: 'center', bold: true }
          ]
        ]
        newDatas.forEach((info, index) => {
          allRows.push([
            { text: (i18n.locale === 'bn') ? info.division_name_bn : info.division_name, style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? info.commodity_name_bn : info.commodity_name, style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? info.unit_name_bn : info.unit_name, style: 'td', alignment: 'center' },
            { text: vm.$n(info.avg_retail_lowestPrice, { useGrouping: false }) + '-' + vm.$n(info.avg_retail_howestPrice, { useGrouping: false }), style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? info.w_unit_name_bn : info.w_unit_name, style: 'td', alignment: 'center' },
            { text: vm.$n(info.avg_wholesale_lowestPrice, { useGrouping: false }) + '-' + vm.$n(info.avg_wholesale_highestPrice, { useGrouping: false }), style: 'td', alignment: 'center' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '10%', '20%', '20%', '20%', '20%'],
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('division-wise-average-market-price-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
